import styled from 'styled-components';

import {StyledText} from '~components/atoms/Text/styles';
import breakpoints from '~constants/breakpoints';

export const Toggle = styled.div`
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: absolute;
    left: 8px;
    top: 0;
    bottom: 0;
    height: 100%;

    z-index: 3;
`;

export const ToggleButton = styled.button`
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    background: transparent;
    padding: 0;
    height: 100%;
    writing-mode: vertical-rl;
    outline: none;
    border: 0;
    align-items: center;
    justify-content: center;
    gap: 12px;

    ${StyledText} {
        writing-mode: vertical-rl;
        margin: 0;
    }

    &::before {
        content: '';
        display: inline-block;
        height: 28px;
        width: 28px;
        background: url('/assets/icons/logo.png') no-repeat center;
        background-size: contain;
        margin-bottom: 10px;
    }

    &::after {
        width: 16px;
        height: 16px;
        content: '';
        background: url('/assets/icons/arrow-right.svg') no-repeat center;
        margin-top: 10px;
        transition: all 0.5s ease-in-out;
        background-size: contain;
    }

    &:hover {
        &::after {
            filter: brightness(1);
        }
    }
`;

export const TokenContainer = styled.div`
    &.open {
        transform: translate3d(0, 0, 0);
    }

    &.closed {
        transform: translate3d(calc(100% - 40px), 0, 0);

        ${ToggleButton}::after {
            transform: rotate(-180deg);
        }
    }

    ${breakpoints.aboveTablet} {
        &.open {
            transform: translate3d(0, 0, 0);
        }

        &.closed {
            transform: translate3d(calc(100% - 40px), 0, 0);
        }
    }

    transition: all 0.5s ease-in-out;

    z-index: 1000;

    position: fixed;
    bottom: 30px;
    right: 0;
    margin-top: 10px;
    width: 520px;
    max-width: calc(100vw - 60px);

    ${breakpoints.abovePhone} {
        bottom: 80px;
    }

    ${breakpoints.aboveTablet} {
        bottom: 10%;
    }
`;

export const Container = styled.div`
    display: flex;
    align-items: center;
    background: linear-gradient(
            123.54deg,
            rgba(63, 89, 240, 0.2) 0.22%,
            rgba(0, 3, 27, 0.2) 76.24%
        ),
        #000000;
    border-radius: 24px 0 0 24px;
    flex-direction: row;
    justify-content: flex-start;
    margin: 0;
    padding: 16px;
    position: relative;

    &:after {
        position: absolute;
        content: '';
        display: block;
        height: 100%;
        width: 366px;
        max-width: 100%;
        top: 0;
        right: 0;
        background-size: cover;
        background: url('/assets/bg/tokenInfo.svg') no-repeat right center;
        background-size: contain;
    }
`;

export const Column = styled.div`
    flex-direction: column;
    padding-left: 60px;
    width: 100%;
    z-index: 2;
`;

export const Wrapper = styled.div`
    position: relative;
    margin-bottom: 10px;
    max-width: 100%;
`;

export const TokenAddressContainer = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    ${StyledText} {
        display: inline-block;
        max-width: calc(100% - 17px);
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &::after {
        content: '';
        display: inline-flex;
        width: 15px;
        height: 15px;
        background: url('/assets/icons/copy.svg') no-repeat center;
        background-size: contain;
        margin-left: 5px;
    }
`;
