import React, {useCallback, useState} from 'react';
import {toast} from 'react-toastify';
import {CopyToClipboard} from 'react-copy-to-clipboard';

import Text from '~components/atoms/Text';
import {TokenInfo as TokenInfoProps} from '~interfaces/graphql/TokenInfo';

import {
    Column,
    Container,
    Toggle,
    ToggleButton,
    TokenAddressContainer,
    TokenContainer,
    Wrapper,
} from './styles';

const TokenInfo = ({
    sectionTitle,
    nameLabel,
    nameValue,
    addressValue,
    addressLabel,
    decimalLabel,
    decimalValue,
    copySuccessMsg,
}: TokenInfoProps) => {
    const [open, toggleOpen] = useState(false);

    const valueCopied = useCallback(() => {
        toast.success(copySuccessMsg, {
            position: 'top-center',
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }, []);

    return (
        <TokenContainer className={open ? 'open' : 'closed'}>
            <Container>
                <Toggle>
                    <ToggleButton onClick={() => toggleOpen((val) => !val)}>
                        <Text type="content" bold color="pink">
                            {sectionTitle}
                        </Text>
                    </ToggleButton>
                </Toggle>
                <Column>
                    <Wrapper>
                        <Text type="sectionName" bold>
                            {sectionTitle}
                        </Text>
                    </Wrapper>
                    <Wrapper>
                        <Text type="content" color="pink" noMarginBottom>
                            {nameValue}
                        </Text>
                        <Text type="content" noMarginTop>
                            {nameLabel}
                        </Text>
                    </Wrapper>
                    <Wrapper>
                        <CopyToClipboard text={addressValue} onCopy={valueCopied}>
                            <TokenAddressContainer>
                                <Text
                                    type="content"
                                    color="pink"
                                    noMarginBottom
                                    noMarginTop
                                >
                                    {addressValue}
                                </Text>
                            </TokenAddressContainer>
                        </CopyToClipboard>
                        <Text type="content" noMarginTop>
                            {addressLabel}
                        </Text>
                    </Wrapper>
                    <Wrapper>
                        <Text type="content" color="pink" noMarginBottom>
                            {decimalValue}
                        </Text>
                        <Text type="content" noMarginTop noMarginBottom>
                            {decimalLabel}
                        </Text>
                    </Wrapper>
                </Column>
            </Container>
        </TokenContainer>
    );
};

export default TokenInfo;
